export default {
  // Within _variables.scss, you’ll find our color variables and Sass map. Here’s an example of the $colors Sass map:
  brown: '#655641',
  darkBrown: '#301D0E',
  black: '#363636',
  beige: '#bcb5a4',
  orange: '#f7921e',
  brightOrange: '#FA8200',
  gray: '#EBE9E4',
  white: '#FFFFFF',
  lightGray: '#F7F6F4',
  primary: '#f7921e',
  mobileMin: '320px',
  mobileMax: '767px',
  tabletMin: '768px',
  tabletMax: '991px',
  desktopMin: '992px'
}

/*
Bootstrap Grid Widths:
xs: <576px,
sm: >=576px,
md: >=768px,
lg: >=992px,
xl: >=1200px
*/
